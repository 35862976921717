import { Common, device } from '@stockbit/ui';
import Image from 'global/Image';
import styled from 'styled-components';
import { Wrapper } from '../styled';

const { Text, Flex } = Common;

const SectionWrapper = styled(Wrapper)`
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const BenefitWrapper = styled(Flex).attrs({
  direction: 'column',
  align: 'center',
})`
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${device.tablet} {
    margin-bottom: 0;

    &:first-child {
      margin-right: 20px;
    }
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const BenefitImage = styled(Image)`
  height: 120px;
  margin-bottom: 40px;

  @media ${device.laptop} {
    height: auto;
  }
`;

const Title = styled(Text).attrs({
  as: 'h3',
  family: 'bold',
  align: 'center',
  mb: '20px',
})`
  font-size: 25.5px;

  @media ${device.tablet} {
    font-size: 28.5px;
  }
`;

const Description = styled(Text).attrs({
  family: 'light',
  align: 'center',
  height: 1.4,
})`
  font-size: 17px;

  @media ${device.tablet} {
    font-size: 19px;
  }
`;

export { SectionWrapper, BenefitWrapper, Title, Description, BenefitImage };
